import * as React from "react";

function SvgCircleChecked(props: any) {
  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <defs>
        <path id="circle-checked_svg__b" d="M0 0h160v160H0z" />
      </defs>
      <g transform="translate(-490 -235)" fill="none" fillRule="evenodd">
        <g transform="translate(490 235)">
          <mask id="circle-checked_svg__c" fill="#fff">
            <use xlinkHref="#circle-checked_svg__b" />
          </mask>
          <path
            d="M13.333 80c0 36.819 29.848 66.667 66.667 66.667S146.667 116.819 146.667 80 116.819 13.333 80 13.333 13.333 43.181 13.333 80c0 24.546 0 24.546 0 0zm121.212 0c0 30.125-24.42 54.545-54.545 54.545S25.455 110.125 25.455 80 49.875 25.455 80 25.455 134.545 49.875 134.545 80c0 20.083 0 20.083 0 0zM70 88.215l35.286-35.286 9.428 9.428L70 107.071 45.286 82.357l9.428-9.428L70 88.215z"
            fill="#242836"
            mask="url(#circle-checked_svg__c)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgCircleChecked;
