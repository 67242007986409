import * as React from "react";

function SvgIconClose(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16" {...props}>
      <g fill="#242836" fillRule="evenodd">
        <path d="M.804 16a.797.797 0 01-.567-1.367L14.629.236a.803.803 0 111.136 1.135L1.372 15.768A.793.793 0 01.804 16z" />
        <path d="M15.198 16a.8.8 0 01-.568-.232L.237 1.371A.803.803 0 111.372.235l14.393 14.397A.798.798 0 0115.198 16z" />
      </g>
    </svg>
  );
}

export default SvgIconClose;
