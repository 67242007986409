import { AxiosResponse } from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { _BaseResponse, _InitValues } from "../models";
import mobileApi from "../utils/mobileApi";

const Context = createContext<_InitValues | undefined>({} as _InitValues);

export function InitWrapper({ children }: any) {
  const [appState, setAppState] = useState<_InitValues>();

  useEffect(() => {
    (async () => {
      const { data }: AxiosResponse<_BaseResponse<_InitValues>> = await mobileApi.get("init");
      if (data.processStatus === "Success") {
        setAppState(data.payload);
      }
    })();
  }, []);

  return <Context.Provider value={appState}>{children}</Context.Provider>;
}

export function useInit() {
  return useContext(Context);
}
