import * as React from "react";

function SvgGooglePlay(props: any) {
  return (
    <svg
      id="googlePlay_svg__katman_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 160 41"
      xmlSpace="preserve"
      width="100%"
      height="100%"
      {...props}
    >
      <style>
        {
          ".googlePlay_svg__st1,.googlePlay_svg__st2{fill-rule:evenodd;clip-rule:evenodd;fill:#fffffe}.googlePlay_svg__st2{fill:#fff}"
        }
      </style>
      <g id="googlePlay_svg___x32__-Search-_x2F_-Filter_x2B_Sort-">
        <g
          id="googlePlay_svg___x32_---Search_x5F_Filter_x5F_Sort_x5F_Screen-:L-Desktops-_x28__x2265_1200px_x29_-Max-container-width:-1140px"
          transform="translate(-1115 -1304)"
        >
          <g id="googlePlay_svg__Footer" transform="translate(0 1220)">
            <g id="googlePlay_svg__Download_on_the_Google_Store_Badge_TR_blk_100217-Copy" transform="translate(1115 84)">
              <g id="googlePlay_svg__Group-3">
                <path
                  id="googlePlay_svg__Fill-4-Copy"
                  d="M7.1 41c-.6 0-1.3-.1-2-.2s-1.2-.3-1.8-.6c-.6-.3-1.1-.6-1.5-1.1-.4-.4-.8-.9-1.1-1.5-.3-.5-.5-1.1-.6-1.8-.1-.7-.2-1.5-.2-2V8.1v-1c0-.6 0-1.3.2-2 .1-.7.3-1.2.6-1.8.3-.6.6-1.1 1.1-1.5.4-.4.9-.8 1.5-1.1C3.8.4 4.4.2 5.1.1c.8 0 1.5-.1 2-.1h145.8c.5 0 1.3 0 2 .2.6.1 1.2.3 1.8.6.5.3 1.1.6 1.5 1.1.4.4.8.9 1.1 1.5.3.5.5 1.1.6 1.8.1.7.2 1.4.2 2V34c0 .6-.1 1.3-.2 2s-.3 1.2-.6 1.8c-.3.6-.7 1.1-1.1 1.5-.4.4-.9.8-1.5 1.1-.6.3-1.1.5-1.8.6-.7.1-1.4.2-2 .2h-2.2L8.1 41h-1z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </g>
              <path
                id="googlePlay_svg__Fill-7"
                className="googlePlay_svg__st1"
                d="M37.8 32.9h1v-6.4h-1v6.4zm-.1-7.5c0-.3.3-.6.6-.6s.6.3.6.6-.3.6-.6.6-.6-.3-.6-.6z"
              />
              <path
                id="googlePlay_svg__Fill-8"
                className="googlePlay_svg__st1"
                d="M40.4 28.1h.9v.8h.1c.2-.5.7-.9 1.5-.9 1.1 0 1.7.6 1.7 1.8v3.1h-1V30c0-.8-.3-1.2-1.1-1.2s-1.2.5-1.2 1.2v2.8h-1v-4.7"
              />
              <path
                id="googlePlay_svg__Fill-9"
                className="googlePlay_svg__st1"
                d="M46.9 30.5c0 1 .5 1.6 1.3 1.6s1.3-.6 1.3-1.6-.5-1.6-1.3-1.6-1.3.6-1.3 1.6zm-1 0c0-1.5.8-2.5 2-2.5.7 0 1.2.3 1.5.8h.1v-2.6h1v6.7h-.9v-.8h-.1c-.3.5-.9.8-1.5.8-1.3.1-2.1-.8-2.1-2.4z"
              />
              <path
                id="googlePlay_svg__Fill-10"
                className="googlePlay_svg__st1"
                d="M52 32.9h1v-4.8h-1v4.8zm-.1-6.2c0-.3.3-.6.7-.6.4 0 .7.3.7.6s-.3.6-.7.6c-.5-.1-.7-.3-.7-.6z"
              />
              <path
                id="googlePlay_svg__Fill-11"
                className="googlePlay_svg__st1"
                d="M54.6 28.1h.9v.7h.1c.1-.5.7-.8 1.3-.8h.4v1c-.1 0-.4-.1-.5-.1-.7 0-1.2.5-1.2 1.1v2.9h-1v-4.8"
              />
              <path
                id="googlePlay_svg__Fill-12"
                className="googlePlay_svg__st1"
                d="M58.4 32.9h1v-4.8h-1v4.8zm-.1-6.2c0-.3.3-.6.7-.6.4 0 .7.3.7.6s-.3.6-.7.6c-.5-.1-.7-.3-.7-.6z"
              />
              <path
                id="googlePlay_svg__Fill-13"
                className="googlePlay_svg__st1"
                d="M61 28.1h.9v.8h.1c.2-.5.7-.9 1.5-.9 1.1 0 1.7.6 1.7 1.8v3.1h-1V30c0-.8-.3-1.2-1.1-1.2-.7 0-1.2.5-1.2 1.2v2.8h-1v-4.7"
              />
              <path id="googlePlay_svg__Fill-22" className="googlePlay_svg__st1" d="M120.3 12.9l1.1-4.8h2l-1.3 4.8h-1.8" />
              <path
                id="googlePlay_svg__Fill-23"
                className="googlePlay_svg__st1"
                d="M125.4 16.5c0 2 1 3.3 2.6 3.3s2.6-1.3 2.6-3.3c0-1.9-1-3.2-2.6-3.2s-2.6 1.2-2.6 3.2zm-2.1 0c0-3 1.7-4.9 4.1-4.9 1.4 0 2.5.6 3.1 1.7V8.1h2v13.3h-2v-1.6c-.6 1.1-1.7 1.7-3.2 1.7-2.3-.1-4-2-4-5z"
              />
              <path
                id="googlePlay_svg__Fill-25"
                className="googlePlay_svg__st1"
                d="M144.8 11.7h1.9v1.7c.6-1.2 1.6-1.8 3.1-1.8 2.2 0 3.4 1.4 3.4 3.6v6.2h-2v-5.8c0-1.5-.7-2.3-2.1-2.3-1.5 0-2.3 1-2.3 2.5v5.6h-2v-9.7"
              />
              <path
                id="googlePlay_svg__Fill-21"
                className="googlePlay_svg__st1"
                d="M136 15.7h4.9c0-1.5-1-2.5-2.4-2.5s-2.4 1-2.5 2.5zm6.8 2.9c-.3 1.8-2 3-4.2 3-2.9 0-4.6-1.9-4.6-4.9 0-3 1.8-5 4.5-5s4.4 1.8 4.4 4.8v.7H136v.1c0 1.7 1.1 2.7 2.6 2.7 1.1 0 2-.5 2.3-1.4h1.9z"
              />
              <g id="googlePlay_svg__Group-3-Copy" transform="translate(35.545 8.348)">
                <path
                  id="googlePlay_svg__Fill-1"
                  className="googlePlay_svg__st2"
                  d="M51.9 11.3l-.9.8-.6.3c-.6.3-1.2.3-1.8.3-.6 0-1.5 0-2.4-.7-1.3-.9-1.8-2.4-1.8-3.8 0-2.8 2.3-4.1 4.1-4.1.6 0 1.3.2 1.9.5.9.6 1.1 1.4 1.3 1.8l-4.2 1.7-1.4.1c.5 2.3 2 3.6 3.7 3.6.9.1 1.5-.2 2.1-.5 0 0 .1 0 0 0zm-2.6-4.5c.3-.1.5-.2.5-.5 0-.7-.8-1.5-1.8-1.5-.7 0-2.1.6-2.1 2.5 0 .3 0 .6.1.9l3.3-1.4z"
                />
                <path
                  id="googlePlay_svg__Fill-2"
                  className="googlePlay_svg__st2"
                  d="M43.1 11.3c0 .7.1.8.7.9.3 0 .6.1.9.1l-.7.4h-3.2c.4-.5.5-.6.5-.9V.7h-1.4l1.4-.7h2.6c-.6.3-.7.5-.8 1.1v10.2"
                />
                <path
                  id="googlePlay_svg__Fill-3"
                  className="googlePlay_svg__st2"
                  d="M38.2 5.1c.4.3 1.3 1.1 1.3 2.4 0 1.3-.8 1.9-1.5 2.5-.2.2-.5.5-.5.9s.3.6.5.8l.6.5c.8.7 1.5 1.3 1.5 2.5 0 1.7-1.6 3.4-4.7 3.4-2.6 0-3.9-1.2-3.9-2.6 0-.6.3-1.6 1.4-2.2 1.1-.7 2.6-.8 3.4-.8-.3-.3-.5-.7-.5-1.2 0-.3.1-.5.2-.7h-.6c-1.9 0-3-1.4-3-2.8 0-.8.4-1.7 1.2-2.4 1-.8 2.2-1 3.2-1h3.7l-1.2.6h-1.1v.1zM36.9 13h-.4c-.2 0-1.1 0-1.9.3-.4.1-1.6.6-1.6 1.9 0 1.3 1.3 2.2 3.2 2.2 1.7 0 2.7-.8 2.7-2 0-.9-.6-1.4-2-2.4zm.5-3.4c.4-.4.4-1 .4-1.3 0-1.3-.7-3.3-2.2-3.3-.5 0-1 .2-1.3.6-.3.4-.4.8-.4 1.3 0 1.2.7 3.2 2.2 3.2.5 0 1-.2 1.3-.5z"
                />
                <path
                  id="googlePlay_svg__Fill-4"
                  className="googlePlay_svg__st2"
                  d="M26.9 12.8c-2.9 0-4.4-2.2-4.4-4.2 0-2.4 1.9-4.4 4.7-4.4 2.6 0 4.3 2.1 4.3 4.2-.1 2.2-1.7 4.4-4.6 4.4zm2.2-1.4c.4-.6.5-1.3.5-2 0-1.6-.8-4.6-3-4.6-.6 0-1.2.2-1.6.6-.7.6-.8 1.4-.8 2.2 0 1.8.9 4.7 3.1 4.7.7 0 1.4-.4 1.8-.9z"
                />
                <path
                  id="googlePlay_svg__Fill-5"
                  className="googlePlay_svg__st2"
                  d="M17.3 12.8c-2.9 0-4.4-2.2-4.4-4.2 0-2.4 1.9-4.4 4.7-4.4 2.6 0 4.3 2.1 4.3 4.2 0 2.2-1.6 4.4-4.6 4.4zm2.3-1.4c.4-.6.5-1.3.5-2 0-1.6-.8-4.6-3-4.6-.6 0-1.2.2-1.6.6-.7.6-.8 1.4-.8 2.2 0 1.8.9 4.7 3.1 4.7.6 0 1.3-.4 1.8-.9z"
                />
                <path
                  id="googlePlay_svg__Fill-6"
                  className="googlePlay_svg__st2"
                  d="M11.7 12.3l-2.4.6c-1 .2-1.9.3-2.8.3C1.8 13.1 0 9.7 0 7 0 3.7 2.5.7 6.9.7c.9 0 1.8.1 2.6.4 1.3.4 1.9.8 2.2 1l-1.4 1.3-.6.2.4-.7c-.6-.5-1.6-1.6-3.6-1.6-2.7 0-4.7 2-4.7 5 0 3.2 2.3 6.2 6 6.2 1.1 0 1.6-.2 2.2-.4V9.3l-2.6.2 1.4-.7h3.6l-.4.4c-.1.1-.1.1-.2.3v2.8"
                />
                <path
                  id="googlePlay_svg__Fill-7_1_"
                  className="googlePlay_svg__st2"
                  d="M57.2 11.4V16h-.9V4.4h.9v1.3c.6-.9 1.7-1.5 2.9-1.5 2.3 0 3.8 1.7 3.8 4.4S62.4 13 60.1 13c-1.1 0-2.2-.6-2.9-1.6zM63 8.7c0-2-1.1-3.6-3-3.6-1.2 0-2.3.9-2.8 1.7v3.7c.5.8 1.6 1.8 2.8 1.8 2 0 3-1.6 3-3.6z"
                />
                <path id="googlePlay_svg__Fill-8_1_" className="googlePlay_svg__st2" d="M65.2 1.2h.9v11.6h-.9z" />
                <path
                  id="googlePlay_svg__Fill-9_1_"
                  className="googlePlay_svg__st2"
                  d="M76.4 15.2c.2.1.5.1.7.1.6 0 1-.2 1.3-1l.7-1.5-3.6-8.4h1l3.1 7.3 3.1-7.3h1l-4.4 10.2c-.5 1.1-1.2 1.6-2.2 1.6-.3 0-.7-.1-.9-.1l.2-.9"
                />
                <path
                  id="googlePlay_svg__Fill-10_1_"
                  className="googlePlay_svg__st2"
                  d="M73.8 12.8c-.1-.3-.1-.5-.1-.8v-.7c-.3.5-.8.8-1.3 1.1-.6.3-1.1.4-1.9.4-.9 0-1.6-.2-2.1-.7-.5-.4-.7-1-.7-1.8s.4-1.4 1.1-1.8c.6-.3 1.5-.5 2.7-.5h2.1V7c0-.6-.2-1.1-.6-1.4-.4-.4-1-.6-1.8-.6-.7 0-1.3.2-1.7.5-.4.3-.6.7-.6 1.2H68c0-.6.3-1.2.9-1.7.6-.5 1.4-.7 2.4-.7s1.8.2 2.4.7c.6.5.9 1.1.9 2v4.1c0 .3 0 .6.1.9 0 .3.1.6.2.8h-1.1zm-3.3-.6c.8 0 1.3-.2 1.9-.5.6-.3 1-.8 1.2-1.3V8.8h-2.1c-.8 0-1.5.2-2 .5-.5.4-.8.8-.8 1.3s.2.9.5 1.2c.3.3.7.4 1.3.4z"
                />
              </g>
              <g id="googlePlay_svg__g1231" transform="translate(0 -.008)">
                <linearGradient
                  id="googlePlay_svg__path1192_1_"
                  gradientUnits="userSpaceOnUse"
                  x1={3435.268}
                  y1={-670.296}
                  x2={3423.396}
                  y2={-664.168}
                  gradientTransform="matrix(1.3204 0 0 2.558 -4518.62 1723.788)"
                >
                  <stop offset={0} stopColor="#00a0ff" />
                  <stop offset={0.007} stopColor="#00a1ff" />
                  <stop offset={0.26} stopColor="#00beff" />
                  <stop offset={0.512} stopColor="#00d2ff" />
                  <stop offset={0.76} stopColor="#00dfff" />
                  <stop offset={1} stopColor="#00e3ff" />
                </linearGradient>
                <path
                  id="googlePlay_svg__path1192"
                  d="M6.7 8.1c-.3.3-.4.7-.4 1.3v20.7c0 .6.2 1 .4 1.3l.1.1 11.6-11.6v-.3L6.7 8.1c.1-.1 0 0 0 0z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="url(#googlePlay_svg__path1192_1_)"
                />
                <linearGradient
                  id="googlePlay_svg__path1203_1_"
                  gradientUnits="userSpaceOnUse"
                  x1={3781.195}
                  y1={-158.064}
                  x2={3759.407}
                  y2={-158.064}
                  gradientTransform="matrix(1.0374 0 0 .8735 -3893.973 157.79)"
                >
                  <stop offset={0} stopColor="#ffe000" />
                  <stop offset={0.409} stopColor="#ffbd00" />
                  <stop offset={0.775} stopColor="orange" />
                  <stop offset={1} stopColor="#ff9c00" />
                </linearGradient>
                <path
                  id="googlePlay_svg__path1203"
                  d="M22.2 23.7l-3.9-3.9v-.3l3.9-3.9.1.1 4.6 2.6c1.3.7 1.3 2 0 2.7l-4.7 2.7c.1 0 0 0 0 0z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="url(#googlePlay_svg__path1203_1_)"
                />
                <linearGradient
                  id="googlePlay_svg__path1210_1_"
                  gradientUnits="userSpaceOnUse"
                  x1={3159.461}
                  y1={-415.349}
                  x2={3146.984}
                  y2={-399.162}
                  gradientTransform="matrix(1.7038 0 0 1.3133 -5363.002 567.354)"
                >
                  <stop offset={0} stopColor="#ff3a44" />
                  <stop offset={1} stopColor="#c31162" />
                </linearGradient>
                <path
                  id="googlePlay_svg__path1210"
                  d="M22.3 23.7l-3.9-3.9L6.7 31.4c.4.5 1.1.5 1.9.1l13.7-7.8"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="url(#googlePlay_svg__path1210_1_)"
                />
                <linearGradient
                  id="googlePlay_svg__path1223_1_"
                  gradientUnits="userSpaceOnUse"
                  x1={3149.85}
                  y1={-429.819}
                  x2={3155.421}
                  y2={-422.587}
                  gradientTransform="matrix(1.7038 0 0 1.3126 -5363.002 565.405)"
                >
                  <stop offset={0} stopColor="#32a071" />
                  <stop offset={0.069} stopColor="#2da771" />
                  <stop offset={0.476} stopColor="#15cf74" />
                  <stop offset={0.801} stopColor="#06e775" />
                  <stop offset={1} stopColor="#00f076" />
                </linearGradient>
                <path
                  id="googlePlay_svg__path1223"
                  d="M22.3 15.8L8.6 8c-.8-.4-1.5-.4-1.9.1l11.6 11.6 4-3.9z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="url(#googlePlay_svg__path1223_1_)"
                />
                <path
                  id="googlePlay_svg__path1225"
                  d="M22.2 23.6L8.6 31.3c-.8.4-1.4.4-1.9 0l-.1.1.1.1c.4.4 1.1.4 1.9 0l13.6-7.9z"
                  opacity={0.2}
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
                <path
                  id="googlePlay_svg__path1227"
                  d="M26.8 20.9l-4.7 2.6.1.1 4.6-2.6c.7-.4 1-.9 1-1.3 0 .5-.4.9-1 1.2z"
                  opacity={0.12}
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
                <path
                  id="googlePlay_svg__path1229"
                  d="M8.6 8.2l18.2 10.3c.6.3.9.8 1 1.2 0-.5-.3-1-1-1.3L8.6 8c-1.3-.7-2.3-.1-2.3 1.4v.1c0-1.5 1-2.1 2.3-1.3z"
                  opacity={0.25}
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgGooglePlay;
