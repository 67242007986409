import { Box, Center, Flex, HStack, Text, useBoolean, useInterval, VisuallyHidden } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import * as StringMask from "string-mask";
import { useAuth } from "../../context/auth";
import { useInit } from "../../context/init";
import { _Translations } from "../../models";
import Button from "../Button";
import LogoDark from "../svg/LogoDark";
var formatter = new StringMask("+$9$0 000 000 00 00");
const OtpForm = () => {
  const hiddenInput = useRef<HTMLInputElement>(null);
  const [otp, setOtp] = useState("");
  const [otpDuration, setOtpDuration] = useState<number>();
  const [enableResend, setEnableResend] = useState<boolean>(false);
  const [active, setActive] = useBoolean();

  useEffect(() => {
    hiddenInput?.current?.focus();
  }, []);

  const auth = useAuth();
  const initValues = useInit();

  useEffect(() => {
    if (initValues?.otpDuration) setOtpDuration(initValues?.otpDuration);
  }, [initValues]);

  useInterval(() => {
    if (otpDuration) {
      setOtpDuration((prev) => (prev ? prev - 1 : 0));
      setEnableResend(false);
    } else if (otpDuration === 0) {
      setEnableResend(true);
    }
  }, 1000);

  const locale = "tr";

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 6) setOtp(e.target.value);
  };

  const onSubmit = async () => {
    setActive.on();
    await auth.verifyOtpAndLogin(otp);
    setActive.off();
    setOtp("");
  };
  const onResend = async () => {
    if (enableResend) {
      await auth.sendOtp(auth.phoneNumber);
      setOtpDuration(initValues?.otpDuration);
    }
  };
  return (
    <Flex direction="column" justifyContent="center" h="100%">
      {/* <Flex h="13.75px" justifyContent="flex-end">
        <Box cursor="pointer" boxSize="16px">
          <IconClose />
        </Box>
      </Flex> */}
      <Center mt="24px" mb="64px" w="100%">
        <Box boxSize="40px">
          <LogoDark></LogoDark>
        </Box>
      </Center>

      <Text fontSize="18px" color="gray.500">
        {formatter.apply(auth.phoneNumber)}
      </Text>
      <Text mx="auto" mt="10px" mb="40px" w="300px" fontSize="16px" color="gray.500">
        {t.description[locale]}
      </Text>
      <Box w="max-content" mx="auto" userSelect="none">
        <HStack style={{ caretColor: "transparent" }} cursor="text" fontSize="24px" onClick={() => hiddenInput?.current?.focus()}>
          {[0, 1, 2, 3, 4, 5].map((d) => (
            <Box color={enableResend ? "orange.500" : "black"} boxSize="16px" key={d}>
              {otp[d] || "-"}
            </Box>
          ))}
        </HStack>
        <VisuallyHidden>
          <input
            style={{ caretColor: "transparent" }}
            ref={hiddenInput}
            type="number"
            onChange={handleOtpChange}
            value={otp}
          ></input>
        </VisuallyHidden>
      </Box>
      <Flex w="max-content" mx="auto" mt="44px" mb="50px">
        <Text
          pointerEvents={enableResend ? "auto" : "none"}
          cursor={enableResend ? "pointer" : "default"}
          onClick={onResend}
          fontSize="16px"
          color={enableResend ? "black" : "gray.500"}
        >
          {t.resendText[locale]}
        </Text>
        <Text ml="5px" fontSize="16px">
          ({otpDuration}s)
        </Text>
      </Flex>
      <Button onClick={onSubmit} buttonText="Tamamla" disabled={otp.length < 6} maxW="250px" loading={active}></Button>
    </Flex>
  );
};

const t: _Translations = {
  description: {
    en: "numaralı telefona gelen doğrulama kodunu gir.",
    tr: "numaralı telefona gelen doğrulama kodunu gir.",
  },
  resendText: {
    en: "Kodu Tekrar Gönder",
    tr: "Kodu Tekrar Gönder",
  },
};

export default OtpForm;
