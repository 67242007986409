// src/context/state.js
import { useBoolean } from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";
import { _BaseResponse } from "../models";

type _Options = {
  status: _BaseResponse<unknown>["processStatus"];
  title: string;
  message: string;
  buttons: {
    onClick: () => void;
    buttonText: string;
    primary?: boolean;
  }[];
};

type _Context = {
  showModal: (fm: _Options) => void;
  closeModal: () => void;
  visible: boolean;
  friendlyMessage?: _Options;
};

const Context = createContext<_Context>({} as _Context);

export function MessageWrapper({ children }: any) {
  const [visible, setVisible] = useBoolean(false);
  const [friendlyMessage, setFriendlyMessage] = useState<_Options>();

  function closeModal() {
    setVisible.off();
    setFriendlyMessage(undefined);
  }
  function showModal(fm: _Options) {
    setVisible.on();
    setFriendlyMessage(fm);
  }
  return (
    <Context.Provider
      value={{
        showModal,
        closeModal, //
        visible,
        friendlyMessage,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useMessage() {
  return useContext(Context);
}
