import { BrowserRouter, Route } from "react-router-dom";
import App from "./App";
const Router = () => {
  return (
    <BrowserRouter>
      <Route path="/:code" exact>
        <App />
      </Route>
      <Route path="/" exact>
        <p>NO CODE</p>
      </Route>
    </BrowserRouter>
  );
};

export default Router;
