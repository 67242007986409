import { Box } from "@chakra-ui/layout";
import { motion } from "framer-motion";
import OtpForm from "./components/auth/OtpForm";
import PhoneNumberForm from "./components/auth/PhoneNumberForm";
import Promo from "./components/promo/Promo";
import { useAuth } from "./context/auth";
const App = () => {
  const auth = useAuth();

  return (
    <Box bg={{ base: "white", md: "#242836" }} w="100vw" minH="100vh" py="10vh">
      <Box
        textAlign="center"
        borderWidth="thin"
        borderColor={{ base: "white", md: "gray.200" }}
        borderRadius="lg"
        boxShadow={{ base: "none", md: "md" }}
        maxW={"600px"}
        mx="auto"
        p="3vh"
        w="100%"
        position="relative"
        bg="white"
        overflow="hidden"
      >
        {auth.viewMode === "phone" && (
          <Framer framerKey={auth.viewMode} isFirst>
            <PhoneNumberForm />
          </Framer>
        )}

        {auth.viewMode === "otp" && (
          <Framer framerKey={auth.viewMode}>
            <OtpForm />
          </Framer>
        )}

        {auth.viewMode === "promo" && (
          <Framer framerKey={auth.viewMode}>
            <Promo />
          </Framer>
        )}
      </Box>
    </Box>
  );
};

const Framer: React.FC<{ isFirst?: boolean; framerKey: string }> = (props) => (
  <Box
    key={props.framerKey}
    as={motion.div}
    boxShadow={{ base: "auto", md: "73vh" }}
    initial={{ x: props.isFirst ? 0 : 200, opacity: props.isFirst ? 1 : 0 }}
    animate={{ x: 0, opacity: 1 }}
  >
    {props.children}
  </Box>
);

export default App;
