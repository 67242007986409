import * as React from "react";

function SvgControlsCheckboxChecked(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width={16} height={16} fill="#242836" rx={2.667} />
        <path
          d="M6.02 11.153L4.191 9.367a.666.666 0 01-.025-.906.616.616 0 01.877-.026L6.421 9.78l4.51-4.917c.226-.258.627-.258.878-.025.25.232.25.646.025.905l-4.937 5.383a.618.618 0 01-.45.207c-.15 0-.326-.052-.427-.181z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default SvgControlsCheckboxChecked;
