import { Box, Center, Flex, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/auth";
import { _Translations } from "../../models";
import useMaskInput from "../../utils/useMaskInput";
import Button from "../Button";
import Contract from "../layout/Contract";
import ControlsCheckboxChecked from "../svg/ControlsCheckboxChecked";
import ControlsCheckboxUnchecked from "../svg/ControlsCheckboxUnchecked";
import LogoDark from "../svg/LogoDark";
const locale = "tr";
const PhoneNumberForm = () => {
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(true);
  const phoneNumber = useMaskInput({
    maskValue: "(*0*)(*0*)(*0*) (*0*)(*0*)(*0*) (*0*)(*0*) (*0*)(*0*)",
  });
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (phoneNumber.rawValue.length >= 10) {
      setIsPhoneNumberInvalid(false);
    } else {
      setIsPhoneNumberInvalid(true);
    }
  }, [phoneNumber]);

  const handleAdPermissionChange = () => {
    auth.setAdPermission.toggle();
  };
  const onSubmit = async () => {
    const _phoneNumber = phoneNumber.rawValue;
    if (_phoneNumber.length < 10) {
      setIsPhoneNumberInvalid(true);
      return;
    }
    setLoading(true);
    await auth.sendOtp(_phoneNumber);
    setLoading(false);
  };
  return (
    <Flex direction="column" justifyContent="center" h="100%">
      <Center mt="24px" mb={{ md: "64px", base: "24px" }} w="100%">
        <Box boxSize="40px">
          <LogoDark></LogoDark>
        </Box>
      </Center>
      <Text mb="20px" fontSize="24px" fontWeight="bold">
        {t.title[locale]}
      </Text>
      <Text fontSize="16px" color="gray.500">
        {t.description[locale]}
      </Text>
      <Box w="max-content" mx="auto">
        <Input
          value={phoneNumber.maskedValue}
          onChange={phoneNumber.handleChange}
          errorBorderColor="orange.500"
          mt={{ md: "48px", base: "10px" }}
          fontSize="24px"
          focusBorderColor="black"
          borderBottomColor="blackAlpha.700"
          pb="10px"
          placeholder={t.inputPlaceholder[locale] as string}
          variant="flushed"
          textAlign="center"
        />
      </Box>
      <Box my={{ md: "48px", base: "30px" }}>
        <Center userSelect="none" onClick={handleAdPermissionChange}>
          <Box cursor="pointer" boxSize="16px">
            {auth.adPermission ? <ControlsCheckboxChecked /> : <ControlsCheckboxUnchecked />}
          </Box>
          <Text ml="8px" color="gray.500" fontSize="12px">
            {t.checkTitle[locale]}
          </Text>
        </Center>
        <Box mt="16px">
          <Text color="gray.500" fontSize="12px" sx={{ b: { color: "black" } }}>
            {t.info[locale]}
          </Text>
        </Box>
      </Box>
      <Button
        maxW="250px"
        onClick={onSubmit}
        buttonText="Onay kodu gönder"
        disabled={isPhoneNumberInvalid}
        loading={loading}
      ></Button>
    </Flex>
  );
};
const contracts = (
  <span>
    * Giriş yaparak <Contract type="preliminary" item_name="Ön Bilgilendirme Formu" name="Ön Bilgilendirme Formu" />,{" "}
    <Contract type="terms" item_name="Kullanım Koşulları" name="Kullanım Koşulları" /> ve{" "}
    <Contract type="privacy" item_name="Gizlilik Sözleşmesi" name="Gizlilik Sözleşmesi" />
    &rsquo;nı onaylamış olursunuz.
  </span>
);
const t: _Translations = {
  title: {
    en: "Sana İyi Geleni Yaşa!",
    tr: "Sana İyi Geleni Yaşa!",
  },
  description: {
    en: "İyi Yaşa’yı keşfetmeye başlamak için telefon numaranı gir!",
    tr: "İyi Yaşa’yı keşfetmeye başlamak için telefon numaranı gir!",
  },
  inputPlaceholder: {
    en: "532 000 00 00",
    tr: "532 000 00 00",
  },
  checkTitle: {
    en: "İyi Yaşa’nın bana özel kampanya, tanıtım ve fırsatlarından haberdar olmak istiyorum.",
    tr: "İyi Yaşa’nın bana özel kampanya, tanıtım ve fırsatlarından haberdar olmak istiyorum.",
  },
  info: {
    en: contracts as any,
    tr: contracts as any,
  },
  invalidMessage: {
    en: "Girdiğiniz numara eksik veya hatalı, kontrol ediniz",
    tr: "Girdiğiniz numara eksik veya hatalı, kontrol ediniz",
  },
};

export default PhoneNumberForm;
