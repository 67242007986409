import { useBoolean, useUpdateEffect } from "@chakra-ui/react";
import { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
import { _User } from "../models/responses/User";
import authService from "../service/auth";
import { useMessage } from "./message";

// import { refreshAccessToken } from "../utils/authHelpers";
// import clientFetch from "../utils/clientFetch";
// import { useAnalytics } from "./analytics";
// import { useMessage } from "./message";

type _Context = {
  sendOtp: (phoneNumber: string) => Promise<void>;
  verifyOtpAndLogin: (otp: string) => Promise<void>;
  adPermission: boolean;
  setAdPermission: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  phoneNumber: string;
  viewMode: "phone" | "otp" | "promo";
  isLoggedIn: boolean;
  isPremium: boolean;
  accessToken: string;
  setViewMode: Dispatch<SetStateAction<"phone" | "otp" | "promo">>;
};

const Context = createContext<_Context>({} as _Context);

export function AuthWrapper({ children }: any) {
  const [user, setUser] = useState<_User>();
  const [viewMode, setViewMode] = useState<_Context["viewMode"]>("phone");
  const [accessToken, setAccessToken] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [adPermission, setAdPermission] = useBoolean(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const message = useMessage();
  useUpdateEffect(() => {
    if (accessToken) {
      onSuccessfulLogin();
    }
  }, [accessToken]);

  async function sendOtp(phoneNumber: string) {
    const { isDone, friendlyMessage } = await authService.send_otp(phoneNumber, adPermission);
    if (isDone) {
      setPhoneNumber(phoneNumber);
      setViewMode("otp");
    } else {
      message.showModal({
        status: "Error",
        title: friendlyMessage?.title || "",
        message: friendlyMessage?.message || "",
        buttons: [],
      });
    }
  }
  async function verifyOtpAndLogin(otp: string) {
    const { isDone, accessToken, friendlyMessage } = await authService.verify_otp_and_login(phoneNumber, otp);

    if (isDone) {
      setAccessToken(accessToken);
      onSuccessfulLogin();
    } else {
      console.log(friendlyMessage);
      message.showModal({
        status: "Error",
        title: friendlyMessage?.title || "",
        message: friendlyMessage?.message || "",
        buttons: [],
      });
    }
  }

  async function onSuccessfulLogin() {
    const user = await authService.get_user_profile(accessToken);
    if (user) {
      setUser(user);
    }
    const { isLoggedIn, isPremium } = authService.user_status(accessToken);
    setLoggedIn(isLoggedIn);
    setIsPremium(isPremium);
    setViewMode("promo");
  }

  return (
    <Context.Provider
      value={{
        sendOtp,
        setViewMode,
        // formVisible,
        // setFormVisible, //
        // otpVisible,
        // setOtpVisible,
        accessToken,
        viewMode,
        setPhoneNumber,
        phoneNumber,
        verifyOtpAndLogin,
        // sendOtp,
        // user,
        // logout,
        adPermission,
        setAdPermission,
        // updateProfile,
        // logoutVisible,
        // setLogoutVisible,
        isLoggedIn,
        isPremium,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useAuth() {
  return useContext(Context);
}
