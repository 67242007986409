import { Text } from "@chakra-ui/react";
import React from "react";
import { useInit } from "../../context/init";

type Props = {
  type: "privacy" | "terms" | "preliminary";
  item_name: string;
  name: string;
};

const Contract: React.FC<Props> = (props) => {
  const initValues = useInit();

  if (initValues)
    return (
      <Text href={initValues[props.type]} target="_blank" rel="noopener noreferrer" as="a" fontWeight="bold" color="black">
        {props.name}
      </Text>
    );
  return null;
};

export default Contract;
