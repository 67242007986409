import axios from "axios";
import config from "../config";

const identityApi = axios.create({
  baseURL: config.IDENTITY_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
  },
  params: {
    culture: "tr",
  },
});

export default identityApi;
