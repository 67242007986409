import { Box, Center, Grid, Spinner, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../../context/auth";
import { useInit } from "../../context/init";
import promoService from "../../service/promo";
import AppGallery from "../svg/AppGallery";
import AppleStore from "../svg/AppleStore";
import SvgCircleChecked from "../svg/CircleChecked";
import SvgErrorOutline from "../svg/ErrorOutline";
import GooglePlay from "../svg/GooglePlay";
const Promo = () => {
  const [result, setResult] = useState<{ isDone: boolean; message: string; title: string }>();
  const auth = useAuth();
  const initValues = useInit();
  const { code } = useParams<{ code: string }>();

  useEffect(() => {
    promoService.apply_code(auth.accessToken, code).then((res) => setResult(res));
  }, []);
  if (result) {
    return (
      <VStack
        h="100%"
        borderRadius="md"
        alignItems="stretch"
        p="30px"
        bg="white"
        position="relative"
        justifyContent="center"
        spacing="50px"
      >
        <Center>
          <Box boxSize="155px">{result.isDone ? <SvgCircleChecked /> : <SvgErrorOutline />}</Box>
        </Center>
        {result.title && (
          <Text textAlign="center" fontSize="24px" fontWeight="bold">
            {result.title}
          </Text>
        )}
        {result.message && (
          <Text textAlign="center" fontSize="20px">
            {result.message}
          </Text>
        )}

        <Box>
          <Text fontSize="20px" fontWeight="bold">
            Uygulamayı İndir
          </Text>
          <Grid
            gap="8px"
            mt="20px"
            templateColumns={{ lg: "1fr 1fr 1fr", base: "1fr" }}
            mx="auto"
            maxW={{ base: "200px", lg: "500px" }}
          >
            <a href={initValues?.storeLinks?.appStore} target="_blank" rel="noopener noreferrer">
              <AppleStore />
            </a>
            <a href={initValues?.storeLinks?.googlePlay} target="_blank" rel="noopener noreferrer">
              <GooglePlay />
            </a>
            <a href={initValues?.storeLinks?.appGallery} target="_blank" rel="noopener noreferrer">
              <AppGallery />
            </a>
          </Grid>
        </Box>
      </VStack>
    );
  }
  return <Spinner></Spinner>;
};

export default Promo;
