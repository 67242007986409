import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import React from "react";
import ReactDOM from "react-dom";
import MessageModal from "./components/message/MessageModal";
import { AuthWrapper } from "./context/auth";
import { InitWrapper } from "./context/init";
import { MessageWrapper } from "./context/message";
import Router from "./Router";

const breakpoints = createBreakpoints({
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1140px",
  "2xl": "2048px",
});

const theme = extendTheme({
  fonts: {
    heading: "DM Sans",
    body: "DM Sans",
  },
  breakpoints,
  components: {
    Switch: {
      baseStyle: {
        track: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <InitWrapper>
        <MessageWrapper>
          <AuthWrapper>
            <Router />
            <MessageModal />
          </AuthWrapper>
        </MessageWrapper>
      </InitWrapper>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
