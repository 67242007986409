import { Center, Text } from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import React from 'react'

type Props = {
  onClick: () => void
  loading?: boolean
  buttonText: string
  disabled?: boolean
  maxW?: string
  second?: boolean
}

const Button: React.FC<Props> = ({
  loading,
  onClick,
  disabled,
  buttonText,
  maxW,
  second,
}) => {
  return (
    <Center
      onClick={onClick}
      pointerEvents={disabled || loading ? 'none' : 'auto'}
      mx="auto"
      maxW={maxW}
      mt="16px"
      userSelect="none"
      cursor="pointer"
      borderRadius="10px"
      bg={disabled ? 'gray.300' : second ? 'white' : 'gray.900'}
      w="100%"
      h="54px"
      color={second ? 'gray.800' : 'white'}
      borderWidth="thin"
      borderColor={disabled ? 'gray.300' : 'gray.900'}
      fontWeight="bold"
    >
      {loading ? (
        <Center
          as={motion.div}
          animate={{ rotate: 360, transition: { loop: Infinity, duration: 1 } }}
        >
          <FontAwesomeIcon icon={faSpinner as IconProp}></FontAwesomeIcon>
        </Center>
      ) : (
        <Text>{buttonText}</Text>
      )}
    </Center>
  )
}

export default Button
