function SvgLogoDark(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 41 54"
      {...props}
    >
      <defs>
        <filter id="logo-dark_svg__a" colorInterpolationFilters="auto">
          <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.141176 0 0 0 0 0.156863 0 0 0 0 0.211765 0 0 0 1.000000 0" />
        </filter>
        <path id="logo-dark_svg__b" d="M0 0h40.498v42.414H0z" />
      </defs>
      <g filter="url(#logo-dark_svg__a)" fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M20.243 9.892c-1.486 0-2.718-.477-3.698-1.432-.98-.954-1.47-2.154-1.47-3.6 0-1.39.49-2.545 1.47-3.472C17.525.464 18.757 0 20.243 0c1.426 0 2.643.464 3.654 1.388 1.009.927 1.515 2.083 1.515 3.471 0 1.447-.506 2.647-1.515 3.601-1.011.955-2.228 1.432-3.654 1.432"
        />
        <g transform="translate(0 11.152)">
          <mask id="logo-dark_svg__c" fill="#fff">
            <use xlinkHref="#logo-dark_svg__b" />
          </mask>
          <path
            fill="#FFF"
            d="M22.81 30.66c.154 1.473.142 4.016-.179 5.47-.19.857-.483 2.45-2.056 2.44-1.163-.007-1.695-1.15-2.008-1.863-.622-1.422-.613-2.654-.627-4.06-.036-3.545.93-7.039 2.325-10.37 1.205 2.656 2.241 5.49 2.545 8.382M38.622.011C30.669.447 24.947 5.798 21.109 12.19c-.293.487-.586.981-.874 1.484-1.885-3.38-4.127-6.726-7.171-9.2C9.839 1.85 6.025.42 1.864.01c-2.494-.244-2.477 3.552 0 3.794 6.19.608 10.537 4.542 13.668 9.517.9 1.43 1.732 2.906 2.516 4.409-2.468 4.977-4.305 10.422-3.973 15.808.211 3.439 1.281 7.45 5.084 8.624 4 1.236 6.28-2.336 7.118-5.607 1.653-6.442-.89-12.947-3.86-18.781.447-.843.906-1.67 1.364-2.48 3.167-5.591 7.785-11.101 14.84-11.49 2.495-.135 2.51-3.93 0-3.794"
            mask="url(#logo-dark_svg__c)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLogoDark;
