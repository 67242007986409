const config = {
  API_URL:
    (window as any).__env__?.REACT_APP_API_URL !== "||REACT_APP_API_URL||"
      ? (window as any).__env__?.REACT_APP_API_URL
      : "https://api.test-be.iyiyasa.com/api/",
  IDENTITY_SERVER_URL:
    (window as any).__env__?.REACT_APP_IDENTITY_SERVER_URL !== "||REACT_APP_IDENTITY_SERVER_URL||"
      ? (window as any).__env__?.REACT_APP_IDENTITY_SERVER_URL
      : "https://auth.test-be.iyiyasa.com/",
  IDENTITY_SERVER_CLIENT_SECRET: "aabbcd4a-1d9a-4ca9-89e8-a90d956ca1b7",
  IDENTITY_SERVER_CLIENT_ID: "com.iyiyasa.ios",
};

export default config;
