import axios from "axios";
import config from "../config";

const mobileApi = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  params: {
    culture: "tr",
  },
});
// mobileApi.interceptors.request.use(async (config) => {
//   let _config = config;
//   return _config;
// });

export default mobileApi;
