import { Box, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useMessage } from "../../context/message";
import Button from "../Button";
import SvgCircleChecked from "../svg/CircleChecked";
import SvgErrorOutline from "../svg/ErrorOutline";
import IconClose from "../svg/IconClose";

const MessageModal: React.FC = () => {
  const message = useMessage();
  return (
    <AnimatePresence>
      {message.visible && (
        <Center
          as={motion.div}
          initial={{ opacity: 0, scale: 1.03 }}
          animate={{ opacity: 1, scale: 1, transition: { duration: 0.45, type: "spring" } }}
          exit={{ opacity: 0, scale: 1.03, transition: { duration: 0.45, type: "spring" } }}
          position="fixed"
          inset="0"
          zIndex="toast"
        >
          <Box bg="blackAlpha.400" position="absolute" inset="0" onClick={message.closeModal} />
          <Box maxW="600px" w="100%" position="relative" h={{ base: "100vh", md: "auto" }}>
            <VStack
              borderRadius="md"
              alignItems="stretch"
              p="30px"
              bg="white"
              h={{ base: "100vh", md: "auto" }}
              position="relative"
              justifyContent="center"
            >
              <Center mb="30px">
                <Box boxSize="155px">
                  {message.friendlyMessage?.status === "Success" ? <SvgCircleChecked /> : <SvgErrorOutline />}
                </Box>
              </Center>
              {message.friendlyMessage?.title && (
                <Text textAlign="center" fontSize="24px" fontWeight="bold" mb="7px">
                  {message.friendlyMessage.title}
                </Text>
              )}
              {message.friendlyMessage?.message && (
                <Text textAlign="center" fontSize="16px" color="gray.600">
                  {message.friendlyMessage.message}
                </Text>
              )}

              {message.friendlyMessage?.buttons.length && (
                <HStack pt="50px">
                  {message.friendlyMessage?.buttons.map((d, i) => (
                    <Button maxW="300px" key={"mb-" + i} buttonText={d.buttonText} onClick={d.onClick}></Button>
                  ))}
                </HStack>
              )}
            </VStack>
            <Box cursor="pointer" boxSize="15px" position="absolute" top="20px" right="20px" onClick={message.closeModal}>
              <IconClose />
            </Box>
          </Box>
        </Center>
      )}
    </AnimatePresence>
  );
};

export default MessageModal;
