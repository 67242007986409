import { AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";
import qs from "qs";
import config from "../config";
import { _BaseResponse } from "../models";
import { _User } from "../models/responses/User";
import identityApi from "../utils/identityApi";
interface _AuthService {
  send_otp: (
    phoneNumber: string,
    adPermission: boolean
  ) => Promise<{ isDone: boolean; friendlyMessage: _BaseResponse<any>["friendlyMessage"] | null }>;
  verify_otp_and_login: (
    phoneNumber: string,
    otp: string
  ) => Promise<{ isDone: boolean; accessToken: string; friendlyMessage: _BaseResponse<any>["friendlyMessage"] | null }>;
  get_user_profile: (accessToken: string) => Promise<_User | null>;
  user_status: (accessToken: string) => { isLoggedIn: boolean; isPremium: boolean };
}

const authService: _AuthService = {
  send_otp: async (phoneNumber: string, adPermission: boolean) => {
    let response = false;
    let friendlyMessage = null;
    try {
      const { data }: AxiosResponse<_BaseResponse<any>> = await identityApi.post("api/auth/register", {
        phoneNumber: "90" + phoneNumber,
        preliminaryPermission: true,
        privacyPermission: true,
        termsPermission: true,
        adsPermission: adPermission,
      });
      if (data.processStatus === "Success") {
        response = true;
      }
      friendlyMessage = data.friendlyMessage;
    } finally {
      return { isDone: response, friendlyMessage };
    }
  },
  verify_otp_and_login: async (phoneNumber: string, otp: string) => {
    let response = { isDone: false, accessToken: "", friendlyMessage: null };
    try {
      const body = qs.stringify({
        grant_type: "otp_auth",
        client_id: config.IDENTITY_SERVER_CLIENT_ID,
        client_secret: config.IDENTITY_SERVER_CLIENT_SECRET,
        scopes: "iyiyasa.api.access profile offline_access",
        otp_token: otp,
        phone_number: "90" + phoneNumber,
      });

      const { data, status }: AxiosResponse<{ access_token: string; friendlyMessage: any }> = await identityApi.post(
        "connect/token",
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      );

      if (status === 200) {
        response.isDone = true;
        response.accessToken = data.access_token;
      }
    } catch (err: any) {
      response.friendlyMessage = err.response.data.friendlyMessage;
    } finally {
      return response;
    }
  },
  get_user_profile: async (accessToken: string) => {
    let response = null;
    try {
      const decoded: any = jwt_decode(accessToken);
      const { data } = await identityApi.get<any, AxiosResponse<_BaseResponse<_User>>>(`user/${decoded.user_id}/profile`);
      if (data.processStatus === "Success") {
        response = data.payload;
      }
    } finally {
      return response;
    }
  },
  user_status: (accessToken: string) => {
    let response = { isLoggedIn: false, isPremium: false };
    try {
      const decoded: any = jwt_decode(accessToken);
      response.isLoggedIn = true;
      if (decoded.user_subscription === "Premium User") {
        response.isPremium = true;
      }
    } finally {
      return response;
    }
  },
};

export default authService;
