import { useMemo, useState } from "react";
//@ts-ignore
import * as StringMask from "string-mask";
function removeDuplicateCharacters(string: string) {
  let unknownString = string.replace(/\(\*\A\*\)/g, "");
  unknownString = unknownString.replace(/\(\*0\*\)/g, "");
  return unknownString.split("").filter(function (item, pos, self) {
    return self.indexOf(item) == pos;
  });
}

const useMaskInput = ({ maskValue }: { maskValue: string }) => {
  const [rawValue, setRawValue] = useState("");
  const [maskedValue, setMaskedValue] = useState("");

  const formatter = useMemo(() => new StringMask(maskValue.replace(/\(\*/g, "").replace(/\*\)/g, "")), []);
  const separators = useMemo(() => removeDuplicateCharacters(maskValue), []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    separators.forEach((a) => {
      value = value.replaceAll(a, "");
    });

    if (value.length === rawValue.length) {
      value = value.slice(0, -1);
    }
    const currentFormattedValue = maskedValue;
    const currentRawValue = rawValue;
    const newFormattedValue = formatter.apply(value);

    if (newFormattedValue.length > currentFormattedValue.length || currentRawValue.length > value.length) {
      setRawValue(value);
      setMaskedValue(newFormattedValue);
    }
  };

  return {
    handleChange,
    maskedValue,
    rawValue,
  };
};

export default useMaskInput;
