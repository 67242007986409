import * as React from "react";

function SvgErrorOutline(props: any) {
  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <defs>
        <path id="error-outline_svg__a" d="M0 0h155v155H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="error-outline_svg__b" fill="#fff">
          <use xlinkHref="#error-outline_svg__a" />
        </mask>
        <path
          d="M77.435 12.917c-35.65 0-64.518 28.933-64.518 64.583s28.868 64.583 64.518 64.583c35.715 0 64.648-28.933 64.648-64.583S113.15 12.917 77.435 12.917c-23.766 0-23.766 0 0 0zm6.523 71.041v-38.75H71.042v38.75h12.916zm0 25.834V96.875H71.042v12.917h12.916zM25.833 77.5c0 28.546 23.121 51.667 51.667 51.667 28.546 0 51.667-23.121 51.667-51.667 0-28.546-23.121-51.667-51.667-51.667-28.546 0-51.667 23.121-51.667 51.667 0 19.03 0 19.03 0 0z"
          fill="#242836"
          mask="url(#error-outline_svg__b)"
        />
      </g>
    </svg>
  );
}

export default SvgErrorOutline;
