import { AxiosResponse } from "axios";
import { _BaseResponse } from "../models";
import mobileApi from "../utils/mobileApi";

interface _PromoService {
  apply_code: (accessToken: string, code: string) => Promise<{ isDone: boolean; message: string; title: string }>;
}

const promoService: _PromoService = {
  apply_code: async (accessToken, code) => {
    let response = { isDone: false, message: "", title: "" };
    try {
      const { data }: AxiosResponse<_BaseResponse<boolean>> = await mobileApi.get(`subscription/promo/${code}`, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      if (data.processStatus === "Success") {
        response.isDone = data.payload;
      }
      response.message = data.friendlyMessage?.message || "";
      response.title = data.friendlyMessage?.title || "";
    } finally {
      return response;
    }
  },
};
export default promoService;
