import * as React from "react";

function SvgControlsCheckboxUnchecked(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16" {...props}>
      <rect width={15} height={15} x={0.5} y={0.5} rx={2.667} stroke="#242836" fill="none" fillRule="evenodd" />
    </svg>
  );
}

export default SvgControlsCheckboxUnchecked;
